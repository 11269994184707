import { Edge, Node } from '@xyflow/react';
import axios from 'axios';
import { OperatorParamType } from './components/nodes/OperatorNode';
import { useMemo, useState } from 'react';

type IOType = 'single' | 'multi';

type Graph = {
  id: string;
  nodes: Node[];
  edges: Edge[];
};

type GraphData = {
  id: string;
  name?: string;
  graph?: Graph;
};

export type GraphListEntry = {
  id: string;
  name?: string;
  isMultiInput?: boolean;
  isMultiOutput?: boolean;
};

export type ProjectData = {
  id: number;
  name: string;
  gpt_key?: string;
  gpt_key_limit?: number;
};

export type NewProjectData = {
  name: string;
  project_token: string;
};

export type User = {
  id: number;
  name?: string | null;
  is_admin: boolean;
};

export type IOInfo = {
  name?: string;
  type: IOType;
};

export type NodeDescription = {
  inputs: IOInfo[];
  outputs: IOInfo[];
  name: string;
  label: string;
  parameters: OperatorParamType[];
};

export type ProcessingStatus =
  | 'new'
  | 'pending'
  | 'success'
  | 'failed'
  | 'cancelled';

export type ProcessingTaskListEntry = {
  id: number;
  graph_id: string;
  graph_name?: string;
  status: ProcessingStatus;
  datasets: Record<number, string>;
  created_at: string;
  updated_at: string;
};

export type ProcessingTaskStats = {
  id: number;
  status: ProcessingStatus;
  graph_id: string;
  n_new: number;
  n_pending: number;
  n_success: number;
  n_failed: number;
  n_cancelled: number;
  n_total: number;
};

export type IOValuesType = string | boolean | null;

type NodeIO = {
  name: string | null;
  values: IOValuesType[];
};

export type CompletionUsage = {
  completion_tokens: number;
  prompt_tokens: number;
  total_tokens: number;
  approximate_cost: number;
};

export type ExecutionResult = {
  output: IOValuesType[];
  results: Record<string, NodeIO[]> | null;
  error: string | null;
  llm_stats: CompletionUsage | null;
  llm_node_stats: Record<string, CompletionUsage> | null;
};

const client = axios.create({
  baseURL: '/api/',
});

// Graphs

export async function listGraphs(
  projectId: string,
  abortController?: AbortController
) {
  const response = await client.get(`/projects/${projectId}/graphs`, {
    signal: abortController?.signal,
  });
  return response.data.graphs as GraphListEntry[];
}

export async function getGraph(
  projectId: string,
  graphId: string,
  abortController?: AbortController
) {
  const response = await client.get(
    `/projects/${projectId}/graphs/${graphId}`,
    {
      signal: abortController?.signal,
    }
  );
  return response.data as GraphData;
}

export async function addGraph(
  projectId: string,
  name: string,
  abortController?: AbortController
) {
  await client.post(
    `/projects/${projectId}/graphs`,
    { name },
    {
      signal: abortController?.signal,
    }
  );
}

export async function updateGraph(
  projectId: string,
  graphId: string,
  graphData: GraphData,
  abortController?: AbortController
) {
  await client.patch(`/projects/${projectId}/graphs/${graphId}`, graphData, {
    signal: abortController?.signal,
  });
}

export async function deleteGraph(
  projectId: string,
  graphId: string,
  abortController?: AbortController
) {
  await client.delete(`/projects/${projectId}/graphs/${graphId}`, {
    signal: abortController?.signal,
  });
}

export async function executeGraph(
  projectId: string,
  graphId: string,
  inputText: string,
  abortController?: AbortController
) {
  const response = await client.post(
    `/projects/${projectId}/graphs/${graphId}/execute`,
    { input: inputText },
    {
      signal: abortController?.signal,
    }
  );
  const respData = response.data as ExecutionResult;
  return respData;
}

// Projects

export async function listProjects(abortController?: AbortController) {
  const response = await client.get(`/projects`, {
    signal: abortController?.signal,
  });
  return response.data.projects as ProjectData[];
}

export async function getProject(
  projectId: string,
  abortController?: AbortController
) {
  const response = await client.get(`/projects/${projectId}`, {
    signal: abortController?.signal,
  });
  return response.data as ProjectData;
}

export async function addProject(
  data: NewProjectData,
  abortController?: AbortController
) {
  return await client.post(`/projects`, data, {
    signal: abortController?.signal,
  });
}
export async function updateProject(
  projectId: string,
  projectData: ProjectData,
  abortController?: AbortController
) {
  await client.patch(`/projects/${projectId}`, projectData, {
    signal: abortController?.signal,
  });
}

export async function deleteProject(
  projectId: string,
  abortController?: AbortController
) {
  await client.delete(`/projects/${projectId}`, {
    signal: abortController?.signal,
  });
}

export async function exportProject(
  projectId: string,
  abortController?: AbortController
) {
  const resp = await client.post(`/projects/${projectId}/export`, {
    signal: abortController?.signal,
  });
  return resp.data;
}

export async function getUser(abortController?: AbortController) {
  const resp = await client.get(`/user`, {
    signal: abortController?.signal,
  });
  return resp.data as User;
}

export async function signUpGoogle(
  googleId: string,
  abortController?: AbortController
) {
  const resp = await client.post(
    '/sign-up-google',
    { credential: googleId },
    {
      signal: abortController?.signal,
    }
  );
  return resp.data as User;
}

export async function loginWithToken(
  token: string,
  abortController?: AbortController
) {
  const resp = await client.post(
    '/login',
    { token },
    {
      signal: abortController?.signal,
    }
  );
  return resp.data as User;
}

export async function logout(abortController?: AbortController) {
  await client.post('/logout', null, {
    signal: abortController?.signal,
  });
}

export async function getOperatorNodesDescriptions(
  abortController?: AbortController
) {
  const resp = await client.get(`/operator-nodes`, {
    signal: abortController?.signal,
  });
  return resp.data as NodeDescription[];
}

// Endpoints

export type EndpointListEntry = {
  name: string;
  graph_id: string;
  graph_name: string;
};

export type EndpointListResponse = {
  endpoints: EndpointListEntry[];
};

export type EndpointData = {
  name: string;
  graph_id: string;
  token?: string;
};

export type DatasetListEntry = {
  id: number;
  name: string;
  n_entries: number;
};

export async function addEndpoint(
  projectId: string,
  data: EndpointData,
  abortController?: AbortController
) {
  await client.post(`/projects/${projectId}/endpoints`, data, {
    signal: abortController?.signal,
  });
}

export async function updateEndpoint(
  projectId: string,
  endpointName: string,
  data: EndpointData,
  abortController?: AbortController
) {
  await client.patch(`/projects/${projectId}/endpoints/${endpointName}`, data, {
    signal: abortController?.signal,
  });
}

export async function deleteEndpoint(
  projectId: string,
  endpointId: string,
  abortController?: AbortController
) {
  await client.delete(`/projects/${projectId}/endpoints/${endpointId}`, {
    signal: abortController?.signal,
  });
}

export async function listEndpoints(
  projectId: string,
  abortController?: AbortController
) {
  const resp = await client.get(`/projects/${projectId}/endpoints`, {
    signal: abortController?.signal,
  });
  const respData: EndpointListResponse = resp.data;
  return respData.endpoints;
}

// Datasets

export async function listDatasets(
  projectId: string,
  abortController?: AbortController
) {
  const resp = await client.get(`/projects/${projectId}/datasets`, {
    signal: abortController?.signal,
  });
  return resp.data as DatasetListEntry[];
}

export async function deleteDataset(
  projectId: string,
  datasetId: number,
  abortController?: AbortController
) {
  await client.delete(`/projects/${projectId}/datasets/${datasetId}`, {
    signal: abortController?.signal,
  });
}

export async function createDataset(
  projectId: string,
  name: string,
  csvFile: File,
  abortController?: AbortController
) {
  await client.postForm(
    `/projects/${projectId}/datasets`,
    {
      name,
      file: csvFile,
    },
    {
      signal: abortController?.signal,
    }
  );
}

// Tasks

export async function listTasks(
  projectId: string,
  abortController?: AbortController
) {
  const resp = await client.get(`/projects/${projectId}/tasks`, {
    signal: abortController?.signal,
  });
  return resp.data as ProcessingTaskListEntry[];
}

export async function getTaskStats(
  projectId: string,
  taskId: number,
  abortController?: AbortController
) {
  const resp = await client.get(
    `/projects/${projectId}/tasks/${taskId}/stats`,
    {
      signal: abortController?.signal,
    }
  );
  return resp.data as ProcessingTaskStats;
}

export async function createTask(
  projectId: string,
  graphId: string,
  datasets: number[],
  abortController?: AbortController
) {
  const resp = await client.post(
    `/projects/${projectId}/tasks`,
    {
      graph_id: graphId,
      datasets,
    },
    {
      signal: abortController?.signal,
    }
  );
  return resp.data.id as number;
}

export async function deleteTask(
  projectId: string,
  taskId: number,
  abortController?: AbortController
) {
  const resp = await client.delete(`/projects/${projectId}/tasks/${taskId}`, {
    signal: abortController?.signal,
  });
  return resp.data.deleted as boolean;
}

export async function rerunFailedTask(
  projectId: string,
  taskId: number,
  abortController?: AbortController
) {
  const resp = await client.post(
    `/projects/${projectId}/tasks/${taskId}/rerun-failed`,
    null,
    {
      signal: abortController?.signal,
    }
  );
  return resp.data.n_reruned as number;
}

export async function cancelTask(
  projectId: string,
  taskId: number,
  abortController?: AbortController
) {
  const resp = await client.post(
    `/projects/${projectId}/tasks/${taskId}/cancel`,
    null,
    {
      signal: abortController?.signal,
    }
  );
  return resp.data.cancelled as boolean;
}

export async function exportTaskResults(
  projectId: string,
  taskId: number,
  abortController?: AbortController
) {
  const resp = await client.get(
    `/projects/${projectId}/tasks/${taskId}/export`,
    {
      signal: abortController?.signal,
      responseType: 'blob',
    }
  );
  console.log('exportTaskResults', typeof resp.data);
  return resp.data as Blob;
}
