import { Handle, Node, NodeProps, Position, useReactFlow } from '@xyflow/react';
import { Button, Form, Input, InputNumber, Tooltip } from 'antd';
import { CSSProperties, useState } from 'react';
import { IOInfo, NodeDataType } from './types';

type ParamDisplayType = 'text' | 'textarea' | 'n-inputs';

export type OperatorParamType = {
  name: string;
  value?: string;
  label?: string;
  required?: boolean;
  type?: ParamDisplayType;
};

export type OperatorNodeDataType = {
  operatorName: string;
  label?: string;
  inputs?: IOInfo[];
  outputs?: IOInfo[];
  params?: OperatorParamType[];
  bgColor?: string;
} & NodeDataType;

export type OperatorNodeType = Node<OperatorNodeDataType, 'Operator'>;

export default function OperatorNode({
  data,
  isConnectable,
  id: nodeId,
}: NodeProps<OperatorNodeType>) {
  const { operatorName, inputs, outputs, label, params = [], bgColor } = data;
  const [changed, setChanged] = useState(false);
  let nInputsParam: OperatorParamType | null = null;
  const formValues = Object.fromEntries(
    params.map((param) => {
      if (param.type === 'n-inputs') {
        nInputsParam = param;
        return [param.name, inputs?.length || 1];
      }
      return [param.name, param.value];
    })
  );

  const { updateNodeData, getEdges } = useReactFlow();

  const usedInputHandles = new Set(
    getEdges()
      .filter((edge) => edge.target === nodeId && edge.targetHandle)
      .map((edge) => edge.targetHandle)
  );

  let minNHandles = 1;
  inputs?.forEach((input, idx) => {
    if (usedInputHandles.has(input.name)) {
      minNHandles = idx + 1;
    }
  });

  const form = params && (
    <Form
      className="operator-node-params-form"
      initialValues={formValues}
      onFinish={(formData: Record<string, any>) => {
        const newParams = params.map((param) => ({
          ...param,
          value: formData[param.name],
        }));
        const nodeUpdate: Record<string, any> = { params: newParams };
        if (nInputsParam) {
          const newNInputs = formData[nInputsParam.name];
          const newInputs: IOInfo[] = Array.from(
            { length: newNInputs },
            (_, idx) => ({ type: 'single', name: idx.toString() })
          );
          nodeUpdate.inputs = newInputs;
        }
        updateNodeData(nodeId, nodeUpdate);
        setChanged(false);
      }}
      onChange={() => setChanged(true)}
    >
      {(params || []).map((param, idx) => (
        <Form.Item
          key={`param-${idx}`}
          name={param.name}
          label={param.label}
          required={param.required}
          rules={
            param.required
              ? [{ required: true, message: 'Required parameter!' }]
              : undefined
          }
          className='operator-node-form-item'
        >
          {param.type === 'textarea' ? (
            <Input.TextArea className="nodrag" cols={60} />
          ) : param.type === 'n-inputs' ? (
            <InputNumber
              min={minNHandles}
              max={100}
              className="nodrag"
              onChange={() => setChanged(true)}
            />
          ) : (
            <Input className="nodrag" />
          )}
        </Form.Item>
      ))}
      {changed && (
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Save
          </Button>
        </Form.Item>
      )}
    </Form>
  );

  const style: CSSProperties = {};
  const minHandleSpace = 15;

  let inputHandles;
  if (inputs && inputs.length) {
    // const space = 100 / inputs.length;
    const requiredSpace = minHandleSpace * inputs.length;
    if (requiredSpace > 100) {
      style.minWidth = requiredSpace;
    }
    const space = 100 / inputs.length;
    const halfspace = space / 2;
    inputHandles = inputs.map(({ name, type }, idx) => (
      <Tooltip title={name} key={idx}>
        <Handle
          id={name}
          key={idx}
          style={{ left: `${idx * space + halfspace}%` }}
          type="target"
          position={Position.Top}
          isConnectable={isConnectable}
          className={type === 'multi' ? 'multi-handle' : ''}
        />
      </Tooltip>
    ));
  }

  let outputHandles;
  if (outputs && outputs.length) {
    const space = 100 / outputs.length;
    const halfspace = space / 2;
    outputHandles = outputs.map(({ name, type }, idx) => (
      <Tooltip title={name} key={idx}>
        <Handle
          id={name}
          key={idx}
          style={{ left: `${idx * space + halfspace}%` }}
          type="source"
          position={Position.Bottom}
          isConnectable={isConnectable}
          className={type === 'multi' ? 'multi-handle' : ''}
        />
      </Tooltip>
    ));
  }

  if (bgColor) {
    style.backgroundColor = bgColor;
  }

  return (
    <div className="operator-node" style={style}>
      {inputHandles}
      {label || operatorName}
      {form}
      {outputHandles}
    </div>
  );
}
