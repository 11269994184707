import { Handle, Node, NodeProps, Position } from '@xyflow/react';
import { NodeDataType } from './types';
import { Tooltip } from 'antd';

export type IfNodeType = Node<NodeDataType, 'If'>;

export default function IfNode({ isConnectable }: NodeProps<IfNodeType>) {
  return (
    <div className="if-node">
      <Tooltip title="Data">
        <Handle
          id="data"
          type="target"
          position={Position.Top}
          isConnectable={isConnectable}
          className="if-node-data-handle"
        />
      </Tooltip>
      <Tooltip title="Condition">
        <Handle
          id="condition"
          type="target"
          position={Position.Top}
          isConnectable={isConnectable}
          className="if-node-condition-handle"
        />
      </Tooltip>
      If
      <Tooltip title="True">
        <Handle
          id="true"
          type="source"
          position={Position.Bottom}
          isConnectable={isConnectable}
          className="if-node-true-handle"
        />
      </Tooltip>
      <Tooltip title="False">
        <Handle
          id="false"
          type="source"
          position={Position.Bottom}
          isConnectable={isConnectable}
          className="if-node-false-handle"
        />
      </Tooltip>
    </div>
  );
}
