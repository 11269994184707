import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { CaretDownOutlined, CaretRightOutlined } from '@ant-design/icons';
import { Button, GetRef, message, Typography } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { AxiosError, CanceledError } from 'axios';
import {
  CompletionUsage,
  executeGraph,
  ExecutionResult,
  IOValuesType,
} from '../client';
import { AppContext } from '../context';

type TestPanelProps = {
  graphId: string;
  onClose: () => void;
  onExecutionResult: (result: ExecutionResult | null) => void;
  edgeData: IOValuesType[] | null;
};

export default function TestPanel({
  graphId,
  onClose,
  edgeData,
  onExecutionResult,
}: TestPanelProps) {
  const [executing, setExecuting] = useState(false);
  const [result, setResult] = useState('');
  const [executionResult, setExecutionResult] =
    useState<ExecutionResult | null>(null);
  const testInputRef = useRef<GetRef<typeof TextArea>>(null);
  const [messageApi, contextHolder] = message.useMessage();
  const { projectId } = useContext(AppContext);

  useEffect(() => {
    if (!executing || projectId === null) return;
    const inputText = testInputRef.current?.resizableTextArea?.textArea.value;
    if (!inputText) {
      setExecuting(false);
      return;
    }
    const abortController = new AbortController();
    (async () => {
      try {
        const resultObj = await executeGraph(
          projectId,
          graphId,
          inputText,
          abortController
        );
        if (resultObj.error) {
          setResult(resultObj.error);
          messageApi.error('Error executing graph');
        } else {
          setResult(resultObj.output.join('\n\n================\n\n'));
        }
        setExecutionResult(resultObj);
        onExecutionResult(resultObj);
      } catch (error) {
        if (error instanceof CanceledError) return;
        messageApi.error('Error executing graph');
        console.log('Error executing graph:', error);
        if (error instanceof AxiosError) {
          setResult(JSON.stringify(error.response?.data));
        }
        setExecutionResult(null);
        onExecutionResult(null);
      }
      setExecuting(false);
    })();
    return () => {
      abortController.abort();
    };
  }, [executing, graphId, messageApi, projectId, onExecutionResult]);

  const edgeTextData = useMemo(
    () => (edgeData ? edgeData.join('\n\n================\n\n') : ''),
    [edgeData]
  );

  const llmStats = executionResult?.llm_stats;
  const llmNodeStats = executionResult?.llm_node_stats;
  let completePrice = false;
  if (llmNodeStats) {
    completePrice = Object.values(llmNodeStats).every(
      (stats) => stats.approximate_cost > 0
    );
  }

  return (
    <div className="test-panel">
      {contextHolder}
      <div className="test-panel-left-col">
        <div className="test-panel-buttons">
          <Button icon={<CaretDownOutlined />} onClick={onClose}>
            Hide
          </Button>
          <Button
            icon={<CaretRightOutlined />}
            onClick={() => setExecuting(true)}
            loading={executing}
            type="primary"
          >
            Run
          </Button>
        </div>
        <TextArea
          ref={testInputRef}
          placeholder="Input text"
          disabled={executing}
        />
      </div>
      <div className="test-panel-middle-col">
        <TextArea value={result} placeholder="Result" readOnly />
        {llmStats && (
          <>
            <Typography.Text>
              Completion tokens: <b>{llmStats.completion_tokens}</b>
            </Typography.Text>
            <Typography.Text>
              Prompt tokens: <b>{llmStats.prompt_tokens}</b>
            </Typography.Text>
            <Typography.Text>
              Total tokens: <b>{llmStats.total_tokens}</b>
            </Typography.Text>
            {llmStats.approximate_cost > 0 && (
              <Typography.Text>
                Approximate cost:{' '}
                <b>
                  {completePrice ? '' : '> '}$
                  {llmStats.approximate_cost.toFixed(6)}
                </b>
              </Typography.Text>
            )}
          </>
        )}
      </div>
      <div className="test-panel-right-col">
        <TextArea value={edgeTextData} placeholder="Object info" readOnly />
      </div>
    </div>
  );
}
